module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[],"preset":{"space":[0,4,8,16,32,64,128,256,512],"fonts":{"body":"system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", sans-serif","heading":"inherit","monospace":"Menlo, monospace"},"fontSizes":[12,14,16,20,24,32,48,64,96],"fontWeights":{"body":400,"heading":700,"bold":700},"lineHeights":{"body":1.5,"heading":1.125},"colors":{"text":"#000","background":"#bababa","primary":"#1f1f22","secondary":"#461587","muted":"#b1b1b1"},"sizes":{"container":1024},"images":{"avatar":{"width":128,"height":128,"borderRadius":99999}},"buttons":{"primary":{"color":"background","bg":"primary","&:hover":{"bg":"text"}},"secondary":{"color":"background","bg":"secondary","&:hover":{"bg":"text"}}},"styles":{"root":{"fontFamily":"body","lineHeight":"body","fontWeight":"body"},"h1":{"color":"text","fontFamily":"heading","lineHeight":"heading","fontWeight":"heading","fontSize":5},"h2":{"color":"text","fontFamily":"heading","lineHeight":"heading","fontWeight":"heading","fontSize":4},"h3":{"color":"text","fontFamily":"heading","lineHeight":"heading","fontWeight":"heading","fontSize":3},"h4":{"color":"text","fontFamily":"heading","lineHeight":"heading","fontWeight":"heading","fontSize":2},"h5":{"color":"text","fontFamily":"heading","lineHeight":"heading","fontWeight":"heading","fontSize":1},"h6":{"color":"text","fontFamily":"heading","lineHeight":"heading","fontWeight":"heading","fontSize":0},"p":{"color":"text","fontFamily":"body","fontWeight":"body","lineHeight":"body"},"a":{"color":"#fff","textDecoration":"none"},"pre":{"fontFamily":"monospace","overflowX":"auto","code":{"color":"inherit"}},"code":{"fontFamily":"monospace","fontSize":"inherit"},"table":{"width":"100%","borderCollapse":"separate","borderSpacing":0},"th":{"textAlign":"left","borderBottomStyle":"solid"},"td":{"textAlign":"left","borderBottomStyle":"solid"},"img":{"display":"block","maxWidth":"100%","marginLeft":"auto","marginRight":"auto"}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"/home/lcastro/blog/lcastrooliveira.github.io/node_modules/gatsby-remark-highlight-code","id":"9ac5e00d-04d3-528d-bcbb-7d384a724d36","name":"gatsby-remark-highlight-code","version":"3.2.0","modulePath":"/home/lcastro/blog/lcastrooliveira.github.io/node_modules/gatsby-remark-highlight-code/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/lcastro/blog/lcastrooliveira.github.io","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"en","locales":"en pt","configPath":"/home/lcastro/blog/lcastrooliveira.github.io/i18n/config.json"},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"defaultLocale":"./i18n/react-intl/en.json"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-162076837-1"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":true,"respectDNT":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
